import React, { useState, useEffect } from "react";
import "./index.scss";
import request from "../../uilts/request";
import { useLocation } from "react-router-dom";
import { urlSplit } from "../../uilts/other";
function Index() {
  const location = useLocation();
  useEffect(() => {
    var element = document.getElementById("video-player");
  }, []);
  return (
    <div className="atomgradientVideo">
      {/* <video
        src={urlSplit(location)}
        // src={require("../../static/bg6zip.mp4")}
        autoPlay
        muted
        id="video-player"
        controls
      ></video> */}
      <video controls id="video-player" autoPlay muted crossOrigin="anonymous">
        <source src={urlSplit(location)} type="video/mp4" />
        {/* <source src={urlSplit(location)} type="video/webm" />
        <source src={urlSplit(location)} type="video/ogg" /> */}
      </video>
      <div className="video-overlay"></div>
    </div>
  );
}
export default Index;
