import Home from "../page/Home";
// import Login from "../page/Login";
import Assistant from "../page/Assistant";
import Information from "../page/Information";
import Video from "../page/Video/index";
import ThreeD from "../page/ThreeD";
// import LoginEn from "../page/LoginEn";
import Termservice from "../page/Termservice";

// import PaymentForm from "../page/components/stripe/PaymentForm";
import Videogast from "../page/VideoGist";
const routes = [
  {
    path: "/" || "/about" || "/joinus",
    element: <Home />,
  },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
  // {
  //   path: "/login/zh/:id?",
  //   element: <Login />,
  // },
  // {
  //   path: "/login/zh/:id?",
  //   element: <Login />,
  // },
  {
    path: "/terms-of-service",
    element: <Termservice />,
  },
  {
    path: "/videogist/:id?",
    element: <Videogast />,
  },
  {
    path: "/assistant",
    element: <Assistant />,
  },
  {
    path: "/information",
    element: <Information />,
  },
  // {
  //   path: "/xuqichao/:id?",
  //   element: <PaymentForm />,
  // },
  {
    path: "/video",
    element: <Video />,
  },
  {
    path: "/3D",
    element: <ThreeD />,
  },
  {
    path: "*",
    element: <Home />,
  },
];
export default routes;
