import React, { useState, useRef, useEffect, useCallback } from "react";
import "./index.scss";
import { useStripe } from "@stripe/react-stripe-js";
// import PayPalButton from "../components/paypal";
const secretaryList = [
  {
    title: "信息搜集与处理",
    content:
      "为用户收集相关信息,如新闻、研究报告或市场动态简化信息,提供摘要或关键点,帮助用户快速了解重要内容。为用户收集相关信息,如新闻、研究报告或市场动态简化信息,提供摘要或关键点,帮助用户快速了解重要内容。",
  },
  {
    title: "隐私助理",
    content:
      "执行简单的在线任务,如预订餐厅、购买电影票或在线购物。管理个人账户,如检查账单、追踪消费或提醒付款日期。",
  },
  {
    title: "教育与培训",
    content:
      "帮助用户学习新技能或语言,通过模拟对话和练习。提供定制化的学习计划和反馈,加速学习进程。",
  },
  {
    title: "健康管理与提醒",
    content:
      "提醒用户按时服药、休息或进行体育锻炼。跟踪健康数据,如步数、睡眠质量或饮食习惯,并提供改善建议。",
  },

  {
    title: "情感支持",
    content:
      "在用户感到压力或需要倾诉时提供情感支持。使用人工智能驱动的情感分析,识别用户情绪并做出适当反应",
  },
  {
    title: "自定义",
    content: "",
  },
  // {
  //   title: "自定义",
  //   content: "",
  // },
  // {
  //   title: "自定义",
  //   content: "",
  // },
  // {
  //   title: "自定义",
  //   content: "",
  // },
];
function Index() {
  const myElementRef = useRef(null);
  const [assistantList, setassistantList] = useState(secretaryList);
  const stripe = useStripe();

  const handlePayment = async () => {
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: "price_1OOY1DKn2G3OjnK21DQXx5GR", quantity: 1 }],
      mode: "payment",
      successUrl: "https://your-website.com/success",
      cancelUrl: "https://your-website.com/cancel",
    });

    if (error) {
      console.error(error);
    }
  };

  const dadsadasd = () => {
    console.log(111);
  };
  window.onscroll = (e) => {
    console.log(window.scrollY);
  };
  useEffect(() => {
    document.documentElement.lang = "zh";

    myElementRef.current.childNodes.forEach((item, index) => {
      item.style = `animation: sdadhbasdbhdnakj .7s ease-in-out ${index}00ms forwards;`;
    });
  }, []);

  const clickCreatecontent = () => {
    console.log(assistantList);
    let obj = { title: "你好", content: "test" };
    // assistantList.push(obj);
    assistantList.splice(assistantList.length - 1, 0, obj);
    setassistantList([...assistantList]);
  };
  return (
    <div className="secretaryHome">
      <div className="ondadhbsadahsbdbbh">
        <header className="secretaryHomeHeader">创建我的助理</header>
        <div className="secretaryHomeHeaderContainer">
          <div>
            我们建议您针对不同的用途创建不同的助理,您可以具备多个助理。单一的助理可以使您的更具有效率。多个助理之间可以相互沟通,以达到协同的效果。我们把助理预先定义为了以下几个类别,可以根据你的需求创建不同的助理。同样类目的助理可以创建多个。{" "}
          </div>
        </div>
        <div className="secretaryHomeContainer">
          <div className="secretaryHomeContainerSAdbahb" ref={myElementRef}>
            {assistantList.map((item, index) => {
              return (
                <div key={index} className="secretaryHomeContainerList">
                  <div className="secretaryHomeContainertitle">
                    {item.title}
                  </div>
                  {item.content.length ? (
                    <>
                      <div className="secretaryHomeContainercontent">
                        {item.content.length > 68
                          ? item.content.substring(0, 68) + "..."
                          : item.content}
                        {/* items?.plan?.city.substring(0, 4) + "..." */}
                      </div>
                      <div
                        className="secretaryHomeContainerCreate"
                        onClick={() => dadsadasd()}
                      >
                        Create
                      </div>
                    </>
                  ) : (
                    <div className="secretaryHomeContainerButton">
                      <div onClick={clickCreatecontent}>Create</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="dsasd" style={{ width: 300 }}>
        {/* <PayPalButton /> */}
        <button onClick={handlePayment}>Pay Now</button>
      </div>

      {/* <footer>3</footer> */}
    </div>
  );
}
export default Index;
