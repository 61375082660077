import React, { useState, useEffect } from "react";
import "./index.scss";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { splitParams, loginRedireact } from "../../uilts/other";
function Index() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [lastScrollTop, setlastScrollTop] = useState(0);
  const [headerFlag, setheaderFlag] = useState(true);
  useEffect(() => {
    document.documentElement.lang = "zh";
    if (splitParams(location.search).token) {
      loginRedireact(splitParams(location.search));
    } else {
    }
  }, [location.search]);
  const homeScroll = (e) => {
    if (
      e.target.scrollTop > lastScrollTop &&
      e.target.scrollTop - 10 > lastScrollTop
    ) {
      // 用户正在向下滚动
      setheaderFlag(false);
      console.log("下");
    } else if (
      e.target.scrollTop < lastScrollTop &&
      e.target.scrollTop < lastScrollTop - 10
    ) {
      // 用户正在向上滚动
      console.log("上");
      setheaderFlag(true);
    }
    setlastScrollTop(e.target.scrollTop);
  };
  const lick = () => {
    if (localStorage.getItem("quwan8Token")) {
      console.log(localStorage.getItem("quwan8Token"));
    } else {
      window.location.href = `http://localhost:3000/login/en?url=${window.location}`;
    }
  };
  return (
    <div className="videogast_home">
      <div className={headerFlag ? "videogast_header" : "videogast_headers"}>
        <h2
          className="videogast_home_logo"
          onClick={() => {
            navigate("/videogist");
          }}
        >
          Videogast
        </h2>
        {params.id ? (
          <div className="gdyasvgtdvaygtvdyascvfdcvay">
            <input
              type="text"
              placeholder="https://"
              className="videogast_container_input_dasdvghcvyf"
            />
            <div className="videogast_container_button">Summarize</div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="videogast_container"
        onScroll={(e) => {
          homeScroll(e);
        }}
      >
        {params.id ? (
          <>
            <div className="dasjodabbvdvsuvcycy">
              <div className="sdnbsvatcvdavsd">
                <div className="asdnahvdcyatsdyfcvgbvygt">
                  <div className="sdbvvtcdrcvknijhiuh">
                    <h2 className="sdabhjbvdsghsvagcvfcfc">
                      What The Prisoner's Dilemma Reveals About Life, The
                      Universe, and Everything
                    </h2>
                    <div className="sdadnsaisbdyuvfsdty">
                      <img
                        src={require("../../static/icon.png")}
                        alt=""
                        className="dabhjvdhgcy"
                      />
                      <div style={{ marginLeft: 10 }}>Veritasium</div>
                      <div style={{ marginLeft: 30 }}>27 min, 19 sec</div>
                    </div>
                  </div>
                  <div className="sdnbajvdsvdugtvgt">
                    <div>share</div>
                    <div>play</div>
                  </div>
                </div>
                <div className="sdnajbduvyafstudcfvtay">
                  The video explores the prisoner's dilemma in game theory,
                  demonstrating how cooperation can emerge even among
                  self-interested entities.
                </div>
                <div className="sdbavuvcdstycadyrc">
                  <h3 style={{ color: "#427eee" }}>SUMMARY</h3>
                  <ul>
                    <li>
                      Introduces the prisoner's dilemma, which is a scenario
                      where individuals must choose between cooperation and
                      defection with various outcomes based on their choices.
                    </li>
                    <li>
                      Describes historical context with the nuclear arms race
                      between the US and the Soviet Union as a real-world
                      example of the prisoner's dilemma.
                    </li>
                    <li>
                      Explains Robert Axelrod's computer tournament that sought
                      the best strategy for the repeated prisoner's dilemma,
                      resulting in 'Tit for Tat' emerging as the most successful
                      strategy.
                    </li>
                    <li>
                      Discusses how the principles of being nice, forgiving,
                      retaliatory, and clear can lead to success in repeated
                      interactions, mirroring moral principles found across
                      cultures.
                    </li>
                    <li>
                      Explores how cooperation can evolve in biological and
                      social systems through repeated interactions, even without
                      trust or conscious thought, potentially encoded in DNA.
                    </li>
                  </ul>
                </div>
                <div className="sandhavdagascfdcaxtdcvyacy">
                  <div className="dashbvdavduvadvcastyc">
                    <div className="sdavdtyacdyrcaytdfvsbdvvcyrcvu">
                      <h3 style={{ color: "#427eee", height: 40 }}>
                        CHAPTER 1
                      </h3>
                      <div className="sdbavyvdtyacyrrfvyu">
                        <div className="sdbajhdvghavdtvc">
                          <div>
                            Introduction to the Most Famous Problem in Game
                            Theory21
                          </div>
                          <div>0:00 - 26 sec</div>
                        </div>
                        <div className="daysfdtfatdtafv">play</div>
                      </div>
                      <div className="dansjdnbavsdtuvsatcyfrcv">
                        The video begins with an introduction to the prisoner's
                        dilemma, a problem in game theory relevant to various
                        real-world scenarios.
                      </div>
                      <ul className="sdaygduyaudtsvfvbhniuhuyftccvyg">
                        <li>
                          The prisoner's dilemma is a widely applicable concept
                          found in game theory that influences decisions in
                          conflict, cooperation, and survival.
                        </li>
                        <li>
                          It is highlighted as a key to understanding
                          cooperation in nature, which appears in contexts
                          ranging from international politics to everyday
                          interactions.
                        </li>
                      </ul>
                    </div>
                    <img src={require("../../static/mobileback.gif")} alt="" />
                  </div>
                </div>
                <div className="sandhavdagascfdcaxtdcvyacy">
                  <div className="dashbvdavduvadvcastyc">
                    <div className="sdavdtyacdyrcaytdfvsbdvvcyrcvu">
                      <h3 style={{ color: "#427eee", height: 40 }}>
                        CHAPTER 1
                      </h3>
                      <div className="sdbavyvdtyacyrrfvyu">
                        <div className="sdbajhdvghavdtvc">
                          <div>
                            Introduction to the Most Famous Problem in Game
                            Theory21
                          </div>
                          <div>0:00 - 26 sec</div>
                        </div>
                        <div className="daysfdtfatdtafv">play</div>
                      </div>
                      <div className="dansjdnbavsdtuvsatcyfrcv">
                        The video begins with an introduction to the prisoner's
                        dilemma, a problem in game theory relevant to various
                        real-world scenarios.
                      </div>
                      <ul className="sdaygduyaudtsvfvbhniuhuyftccvyg">
                        <li>
                          The prisoner's dilemma is a widely applicable concept
                          found in game theory that influences decisions in
                          conflict, cooperation, and survival.
                        </li>
                        <li>
                          It is highlighted as a key to understanding
                          cooperation in nature, which appears in contexts
                          ranging from international politics to everyday
                          interactions.
                        </li>
                      </ul>
                    </div>
                    <img src={require("../../static/mobileback.gif")} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="dsanhausbdabsduiashbdua">
              <div className="videogast_container_headedasdaib">
                <div className="videogast_container_header">
                  Get a concise summary of any video
                </div>
                <div className="videogast_container_input">
                  <input
                    type="text"
                    placeholder="https://"
                    className="videogast_container_input_dasdvghcvyf"
                  />
                  <div
                    className="videogast_container_button"
                    onClick={() => {
                      lick();
                    }}
                  >
                    Summarize
                  </div>
                </div>
              </div>
            </div>
            <div className="videogast_container_list">
              <div className="ndbvtcdyasvbdabvdutytvu">
                <div className="dajsiodabgiydgyvg">Featured summaries</div>
                <div className="dabdgsygayvtfyrdrc">
                  <div className="dasgdvfcytcd">
                    <img
                      src={require("../../static/mobileback.gif")}
                      alt=""
                      className="imgdasvdbacvgdcyhg"
                    />
                    <div
                      onClick={() => {
                        navigate("/videogist/123");
                      }}
                      className="dsnaoubdiabdhasv"
                    >
                      Tim Dillon's Fake Business
                    </div>
                    <div>
                      The speaker engages in fake business calls pretending to
                      be a real estate investor to realtors, discussing
                      properties and deals that are purely fictional.
                    </div>
                  </div>
                  <div className="dasgdvfcytcd">
                    <img
                      src={require("../../static/mobileback.gif")}
                      alt=""
                      className="imgdasvdbacvgdcyhg"
                    />
                    <div
                      onClick={() => {
                        navigate("/videogist/123");
                      }}
                      className="dsnaoubdiabdhasv"
                    >
                      Tim Dillon's Fake Business
                    </div>
                    <div>
                      The speaker engages in fake business calls pretending to
                      be a real estate investor to realtors, discussing
                      properties and deals that are purely fictional.
                    </div>
                  </div>{" "}
                  <div className="dasgdvfcytcd">
                    <img
                      src={require("../../static/mobileback.gif")}
                      alt=""
                      className="imgdasvdbacvgdcyhg"
                    />
                    <div
                      onClick={() => {
                        navigate("/videogist/123");
                      }}
                      className="dsnaoubdiabdhasv"
                    >
                      Tim Dillon's Fake Business
                    </div>
                    <div>
                      The speaker engages in fake business calls pretending to
                      be a real estate investor to realtors, discussing
                      properties and deals that are purely fictional.
                    </div>
                  </div>{" "}
                  <div className="dasgdvfcytcd">
                    <img
                      src={require("../../static/mobileback.gif")}
                      alt=""
                      className="imgdasvdbacvgdcyhg"
                    />
                    <div
                      onClick={() => {
                        navigate("/videogist/123");
                      }}
                      className="dsnaoubdiabdhasv"
                    >
                      Tim Dillon's Fake Business
                    </div>
                    <div>
                      The speaker engages in fake business calls pretending to
                      be a real estate investor to realtors, discussing
                      properties and deals that are purely fictional.
                    </div>
                  </div>{" "}
                  <div className="dasgdvfcytcd">
                    <img
                      src={require("../../static/mobileback.gif")}
                      alt=""
                      className="imgdasvdbacvgdcyhg"
                    />
                    <div
                      onClick={() => {
                        navigate("/videogist/123");
                      }}
                      className="dsnaoubdiabdhasv"
                    >
                      Tim Dillon's Fake Business
                    </div>
                    <div>
                      The speaker engages in fake business calls pretending to
                      be a real estate investor to realtors, discussing
                      properties and deals that are purely fictional.
                    </div>
                  </div>{" "}
                  <div className="dasgdvfcytcd">
                    <img
                      src={require("../../static/mobileback.gif")}
                      alt=""
                      className="imgdasvdbacvgdcyhg"
                    />
                    <div
                      onClick={() => {
                        navigate("/videogist/123");
                      }}
                      className="dsnaoubdiabdhasv"
                    >
                      Tim Dillon's Fake Business
                    </div>
                    <div>
                      The speaker engages in fake business calls pretending to
                      be a real estate investor to realtors, discussing
                      properties and deals that are purely fictional.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default Index;
