//分割url/
// const location = useLocation();
function urlSplit(src) {
  let a = src.search.split("?");
  return a[1].split("=")[1];
}
function splitParams(params) {
  console.log(params);
  if (params) {
    let obj = {};
    let str = params.split("?")[1];
    let arrkey = str.split("&");
    arrkey.forEach((item) => {
      let key = item.split("=");
      obj[key[0]] = key[1];
    });
    return obj;
  } else {
    return false;
  }
}
function loginRedireact(locationMan) {
  localStorage.setItem("quwan8Token", locationMan.token);
  localStorage.setItem(
    "quwan8Id",
    JSON.stringify({
      userId: locationMan.userId,
      id: locationMan.id,
    })
  );
  let urlParams = locationMan;
  delete urlParams.token;
  delete urlParams.userId;
  delete urlParams.id;
  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };
  if (isEmpty(urlParams)) {
    window.location.href = window.location.pathname;
  } else {
    const newUrlParams = new URLSearchParams(urlParams).toString();
    window.location.href = window.location.pathname + `?${newUrlParams}`;
  }
}
export { urlSplit, splitParams, loginRedireact };
