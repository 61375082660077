import React, { useState, useEffect, useCallback } from "react";
import "./index.scss";
import { useLocation, useParams, useNavigate } from "react-router-dom";
function Index() {
  const navigate = useNavigate();
  useEffect(() => {
    document.documentElement.lang = "zh";
  }, []);
  const submitInfo = () => {
    console.log(111);
    navigate("/assistant");
  };
  return (
    <div className="assistantHome">
      <header className="assistantHomeheader">创建我的助理</header>
      <div className="assistantHomeheadercontainer">
        <div>
          什么是分身,分身就是你的7*24秘书,ta可以全天候的帮你你学习,收集,整理,研究,并给你制定计划等等。在这里,你可以创建不同的分身用于不同的目的等等。创建完成后,只要耐心等待或者给你的分身发送任何指令,他就会替你竞成你日常需要完成的工作,让你可以更专注于其他的高优事项。
          贴心的秘书也会时刻跟踪你的待办事项等等,协助你更好的的完成你的事情,让你成为更好的你。
        </div>
      </div>
      <div className="assistantHomeContainer">
        <div className="assistantHomeContainerTitle">个人Info</div>
        <div className="assistantHomeContainerText">
          <textarea name="" id="" rows="15" placeholder=""></textarea>
        </div>
        <button className="assistantHomeContainerButton" onClick={submitInfo}>
          Submit
        </button>
      </div>
    </div>
  );
}
export default Index;
