import React from 'react';
import './PrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy">
      <h1>Perplexity's Privacy Policy</h1>
      <p>Last updated: December 5th, 2023</p>
      
      <div>
        <p>This privacy policy ("Policy") describes how www.perplexity.ai and https://labs.perplexity.ai ("Perplexity.ai", "we", "us", or "our") collects, uses, shares and protects information about you when you use our website, products and services (collectively, the "Services").</p>
      </div>
      
      <h2>Our Services</h2>
      <p>Perplexity AI provides a text generation service. Perplexity AI is available via https://www.perplexity.ai and associated internet domains such as https://labs.perplexity.ai and others.</p>
      
      <h2>Information Perplexity.ai Collects</h2>
      {/* ... Add content for each section following the same pattern ... */}
      
      <h2>Information Perplexity.ai Does Not Collect</h2>
      {/* ... */}
      
      <h2>Sharing of Information</h2>
      {/* ... */}
      
      <h2>Third Party Services</h2>
      {/* ... */}
      
      <h2>Security</h2>
      {/* ... */}
      
      <h2>Data Retention</h2>
      {/* ... */}
      
      <h2>Changes to our Privacy Policy</h2>
      {/* ... */}
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this Policy, please contact us at support@perplexity.ai</p>
    </div>
  );
};

export default PrivacyPolicyPage;
