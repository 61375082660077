import React, { useEffect, useRef } from "react";
// import * as THREE from "three";
// import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";

import "./index.scss";
const MyComponent = () => {
  // const sceneRef = useRef(null);
  // const position = { lat: 61.2176, lng: -149.8997 };
  // useEffect(() => {
  //   // 创建场景
  //   const scene = new THREE.Scene();
  //   // 创建相机
  //   const camera = new THREE.PerspectiveCamera(
  //     75,
  //     window.innerWidth / window.innerHeight,
  //     0.1,
  //     1000
  //   );

  //   // 创建渲染器
  //   const renderer = new THREE.WebGLRenderer();
  //   renderer.setSize(window.innerWidth, window.innerHeight);
  //   sceneRef.current.appendChild(renderer.domElement);

  //   // 创建几何体
  //   const geometry = new THREE.BoxGeometry();
  //   const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
  //   const cube = new THREE.Mesh(geometry, material);
  //   scene.add(cube);

  //   camera.position.z = 5;

  //   // 渲染场景
  //   const animate = () => {
  //     requestAnimationFrame(animate);

  //     cube.rotation.x += 0.1;
  //     cube.rotation.y += 0.1;

  //     renderer.render(scene, camera);
  //   };

  //   animate();
  // }, []);

  return (
    <div className="sdadadsadadas">
      {/* <div ref={sceneRef} className=""></div>
       */}
      {/* <APIProvider apiKey={"YOUR_API_KEY"}>
        <Map center={position} zoom={10}>
          <Marker position={position} />
        </Map>
      </APIProvider> */}
    </div>
  );
};
export default MyComponent;
