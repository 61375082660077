// api.js

import axios from "axios";

const instance = axios.create({
  baseURL: "https://quvanba.com:3002", // 设置基本URL
});
// https://quvan8server.fly.dev
// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    // console.log();
    // 在请求发送前可以在这里进行一些操作，例如添加请求头等
    config.headers["Cache-Control"] = "max-age=604800";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 在响应处理前可以在这里进行一些操作，例如处理响应数据等
    return response;
  },
  (error) => {
    // 在请求错误时处理错误
    return Promise.reject(error);
  }
);

export default instance;
